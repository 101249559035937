<template>
  <b-container class="mt-4">
    <b-form @submit="filterCompetitions">
      <b-card header-tag="header" header-class="header">
        <template #header>
          <label v-text="'Yarışmalar'" class="mr-2 text-white"></label>
          <b-button
            variant="primary"
            to="/addCompetition"
            v-if="hasRole(['federation'])"
            >Yarışma Ekle</b-button
          >
        </template>

        <b-alert show v-if="hasRole(['club'])">
          YARIŞMA GRUBU OLUŞTURMAK: Aşağıdan yarışma grubunu oluşturacağınız
          yarışmayı seçiniz, açılan sayfada
          <b>Yarışma Grubu Oluştur</b> butonuna tıklayınız, daha sonra açılan
          sayfadan katılım şeklini seçiniz, aşağıda açılan seçenekleri eklenecek
          personele göre doldurup gönder butonuna tıklayınız. Bu işlemi
          göndermek istediğiniz tüm kişi grupları için tekrarlayınız.
        </b-alert>
        <b-alert show v-if="hasRole(['federation'])">
          YARIŞMA GÖREVLİSİ GÖNDERME: Aşağıdan yarışma personelini göndereceğiniz
          yarışmayı seçiniz ve açılan sayfada
          <b>Personel Gönder</b> butonuna tıklayınız. Daha sonra açılan
          sayfadan, bilgileri gönderilecek personele uygun olarak doldurduktan sonra
          personeli seçerek <b>Gönder</b> butonuna tıklayınız. Bu işlemi
          göndermek istediğiniz tüm personeller için tekrarlayınız.
        </b-alert>
        <b-row>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="competitionFilter.selectedOptions"
              :options="options"
              size="sm"
              style="margin-top: 0.5rem; margin-left: 1.3rem"
              :aria-describedby="ariaDescribedby"
              name="flavour-1"
            >
            </b-form-checkbox-group>
          </b-form-group>

          <b-col>
            <b-form-input
              placeholder="Ad / Tarih"
              v-model="competitionFilter.where"
            >
            </b-form-input>
          </b-col>
          <b-col>
            <b-button variant="secondary" type="submit">Gönder</b-button>
          </b-col>
        </b-row>
        <hr />

        <PMSTable
          :tableFields="competitionFields"
          :tableItems="getCompetitions"
          :editable="true"
          :erasable="true"
          hasAction="competition"
          deleteMessage="Yarışmayı silmek istediğinizden emin misiniz? Yarışmaya bağlı (karşılaşmalar, gruplar vs) de silincektir!"
          :pageCount="getCompetitionTotalPageCount"
          :selectable="hasRole(['federation'])"
          @matchesPage="matchesPage"
          @onPageChanged="onPageChanged"
          @openDetail="openDetail"
          @printParticipantList="printParticipantList"
          @editClick="editClick($event)"
          @multipleDeleteAction="deleteMultiple($event)"
        ></PMSTable>
      </b-card>
    </b-form>
  </b-container>
</template>

<script>
import PMSTable from "../../components/tables/GeneralTable.vue";
import reportHelper from "@/store/helpers/PMSReportHelper";
import reports from "../../store/constants/reports";
import { mapGetters } from "vuex";
export default {
  components: {
    PMSTable,
  },
  computed: {
    ...mapGetters({
      getCompetitions: "getCompetitions",
      getCompetitionTotalPageCount: "getCompetitionTotalPageCount",
      getCompetitionFilter: "getCompetitionFilter",
    }),
    competitionFilter: {
      get() {
        return this.getCompetitionFilter;
      },
      set(value) {
        this.$store.commit("updateCompetitionFilter", value);
      },
    },
  },
  created() {
    this.$store.dispatch(
      "initCompetitions",
      this.$router.history.current.query.page
    );
    this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
  },
  data() {
    return {
      options: [
        { text: "Yapılacak Yarışmalar", value: "orgWillBeDone" },
        { text: "Sonuçlanmamış Yarışmalar", value: "orgHasDone" },
      ],
      competitionFields: [
        {
          key: "selected",
          label: "Seç",
        },
        {
          key: "id",
          label: "No",
        },
        
        {
          key: "competitionName",
          label: "Ad",
        },
        {
          key: "branch",
          label: "Branş"
        },
        {
          key: "place",
          label: "Yer",
        },
        {
          key: "numberOfAthletesParticipating",
          label: "Katılımcı",
        },
        {
          key: "numberOfClubsParticipating",
          label: "Kulüp",
        },
        {
          key: "numberOfSportsParticipating",
          label: "Sporcu",
        },
        {
          key: "result",
          label: "Sonuç",
        },
       
        {
          key: "place",
          label: "Yer",
        },
        this.$store.getters.checkToken.isFederation
          ? {
              key: "events",
              label: "İşlemler",
            }
          : null,
      ],
    };
  },
  methods: {
    onPageChanged(value) {
      this.$store.dispatch("initCompetitions", value);
    },
    editClick(index) {
      this.$router.push({
        name: "EditCompetition",
        params: { id: this.getCompetitions[index].id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    deleteMultiple(indexes) {
      this.$store.dispatch("deleteCompetitions", indexes);
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    filterCompetitions(event) {
      event.preventDefault();
      this.$store.dispatch("initCompetitions");
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    openDetail(index) {
      this.$router.push({
        name: "CompetitionDetail",
        params: { id: this.getCompetitions[index].id },
      });
      this.$store.commit("updatePMSTablePaginationCurrentPage", 1);
    },
    matchesPage(id) {
      this.$router.push({
        name: "Matches",
        params: { id },
      });
    },
    async printParticipantList(index) {
      reportHelper.generateHTML(
        reports.competitionParticipant.title +
          " \n" +
          this.getCompetitions[index].name +
          " Katılımcı Listesi",
        reports.competitionParticipant.fields,
        await reportHelper.getPdfItemsFromServer(
          reports.competitionParticipant.endpoint,
          { id: this.getCompetitions[index].id }
        )
      );
    },
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        }
      });
      return vals.includes(true);
    },
  },
};
</script>

<style scoped>
.header {
  background: rgb(108, 117, 125);
  background: linear-gradient(
    180deg,
    rgb(108, 117, 125) 10%,
    rgba(53, 57, 61, 1) 85%
  );
}
</style>